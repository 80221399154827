import './App.css';
import GameContainer from './components/GameContainer';

function App() {

  return (
    <div className="App">
      <GameContainer targetNumber={20} />
    </div>
  );
}

export default App;
