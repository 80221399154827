import { useEffect, useState } from "react";
import GameBoard from "./GameBoard.js";

export default function GameContainer({ targetNumber }) {
  const [cells, setCells] = useState([]);
  const [stepCounter, setStepCounter] = useState(0);
  const [highestChain, setHighestChain] = useState(0);
  const [todayHighestChain, setTodayHighestChain] = useState(0);
  const [yesterdayHighestChain, setYesterdayHighestChain] = useState(0);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [stats, setStats] = useState({
    highestChain: 0,
    totalGamesPlayed: 0
  });
  const [selectedCells, setSelectedCells] = useState([]);
  const [scoreboardData, setScoreboardData] = useState([]);
  const [attemptNumber, setAttemptNumber] = useState(1);
  const [attemptIcons, setAttemptIcons] = useState([
    "/images/heart-empty.png", 
    "/images/heart-empty.png", 
    "/images/heart-empty.png", 
    "/images/heart-empty.png",
    "/images/heart-empty.png"
  ])
  const today = new Date();

  function resetGame() {
    console.log("reset");
    selectedCells.map(cell => {
      updateCellStyle(cell, false);
      return cell;
    });
  
    setSelectedCells([]);
    setStepCounter(0)
  }

  function undoSelection() {
    console.log("undoSelection")
    if (selectedCells.length > 0) {
      const lastSelectedCell = selectedCells[selectedCells.length - 1];
      // Create a new array with all cells except the last one
      const updatedSelectedCells = selectedCells.slice(0, -1);
      // Update the state with the new array
      setSelectedCells(updatedSelectedCells);
      // Update the style of the last selected cell
      updateCellStyle(lastSelectedCell, false);
      setStepCounter(updatedSelectedCells.length)
    }
  }

  function updateCellStyle(updateCell, isSelected) {
    setCells(prevCells =>
      prevCells.map(cell =>
        (cell.row === updateCell.row && cell.col === updateCell.col)
          ? { ...cell, isSelected: isSelected } // never unselect on click
          : cell
      )
    );
  }

  function updateScoreboard(chainLength) {
    // Create a new array with the updated data
    const updatedScoreboardData = [
      ...scoreboardData,
      { attemptNumber: attemptNumber, chainLength: chainLength },
    ];

    // Update the state with the new array and increment attemptNumber
    setScoreboardData(updatedScoreboardData);
    const updateIcons = attemptIcons;
    updateIcons[attemptNumber-1] = "/images/heart-full.png";
    setAttemptIcons(updateIcons)
    setAttemptNumber(attemptNumber + 1);

    window.localStorage.setItem('game', JSON.stringify(updatedScoreboardData));
    window.localStorage.setItem('highestChain', highestChain);
  };

  async function gameCompleted(dayHighestChain) {
    const currentHighestChain = highestChain;
    const statsHighest = stats.highestChain;
    const totalGamesPlayed = stats.totalGamesPlayed;

    const updatedStats = {};
    const highestDayChainToday = Math.max(currentHighestChain, dayHighestChain);
    // get highest chain for the day and compare to stats
    updatedStats.highestChain = Math.max(highestDayChainToday, statsHighest);
    // set total games played
    updatedStats.totalGamesPlayed = totalGamesPlayed + 1;
    setStats(updatedStats);

    window.localStorage.setItem('highestChain', Math.max(highestDayChainToday, statsHighest));
    window.localStorage.setItem('stats', JSON.stringify(updatedStats)); 

    // send highest chain value to server
    const strDate = today.getUTCDate() + "/" + (today.getUTCMonth() + 1) + "/" +  today.getUTCFullYear()
    // if there was a change in value then update
    if(todayHighestChain < highestDayChainToday) {
      console.log("send response ");
      await postHighestChain(strDate, highestDayChainToday);
      alert("Congratulations! Your chain is the longest so far today. Nicely done!");
    }
  }

  async function postHighestChain(strDate, dayhighestChain){
    setLoading(true);
    const response = await fetch('/api/HighestChain?date='+strDate+'&highestChain='+dayhighestChain, {
      method: "POST"
    });
    const data = await response.json().catch(error => {
      console.log("error converting response");
      return false;
    });

    if(data) {
      setTodayHighestChain(data.highest);
    }
    setLoading(false);
  }

  useEffect(() => {
    async function getHighestChain(strDate){
      setLoading(true);
      const response = await fetch('/api/HighestChain?date='+strDate);
      const data = await response.json().catch(error => {
        console.log("error converting response");
        return false;
      });

      if(data) {
        setTodayHighestChain(data.today);
        setYesterdayHighestChain(data.yesterday);
      }
      setLoading(false);
    }

    //check the date
    const today = new Date();
    const strDate = today.getUTCDate() + "/" + (today.getUTCMonth() + 1) + "/" +  today.getUTCFullYear()
    const lastPlayedDate = window.localStorage.getItem('startPlay');

    // get the latest highest chain
    getHighestChain(strDate);

    if(lastPlayedDate === strDate) { // same day then let's load the data
      const gameData = window.localStorage.getItem('game');
      const highestChainData = window.localStorage.getItem('highestChain');

      // console.log(gameData);
      if(gameData !== '') {
        const parsedGameData = JSON.parse(gameData)
        setScoreboardData(parsedGameData);
        console.log("scoreboardData",scoreboardData)
        setAttemptNumber(parsedGameData.length + 1);
        console.log(attemptNumber)
        const updateAttempts = attemptIcons;
        for(let i = 0; i < parsedGameData.length; i++){
          updateAttempts[i] = "/images/heart-full.png";
        }
        setAttemptIcons(updateAttempts);
      }

      if(highestChainData !== null) {
        setHighestChain(highestChainData);
      }

      const statsData = window.localStorage.getItem('stats');
      if(statsData) { 
        setStats(JSON.parse(statsData));
      }
    }
    else {
      window.localStorage.setItem('startPlay', strDate);
      // reset fields
      setAttemptNumber(1);
      window.localStorage.setItem('game', []);
      setHighestChain(0);
      window.localStorage.setItem('highestChain', 0);

      // get the stats
      const statsData = window.localStorage.getItem('stats'); 
      if(statsData) { 
        setStats(JSON.parse(statsData));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="game-container">
      <div className="titleBlock">
        <h1 className="title">Finding 20</h1>
        <div className="logo">
          <span className="small">By the makers of</span>
          <img className="icon" src="/images/TS_Logo_Purple_NoSprites.png" alt="Town Squared" height="65" />
        </div>
      </div>
      <div id="game-container">
        <div className="dailyChains">
          <div className="cols">
            <img src="/images/worldwide-yellow.png" alt="" height="50" />
          </div>
          <div className="cols">
            <p className="para">Day: <strong>{today.getUTCDate()}/{today.getUTCMonth() + 1}/{today.getUTCFullYear()} (UTC)</strong></p>
            <p className="para">Today's longest chain:&nbsp;
              {loading ? 'Loading...' : <strong>{todayHighestChain}</strong>}
            </p>
            <p className="para">Yesterday's longest chain:&nbsp;
              {loading ? 'Loading...' : <strong>{yesterdayHighestChain}</strong>}
            </p>
          </div>
        </div>
        
        <GameBoard 
          cells={cells} 
          setCells={setCells} 
          selectedCells={selectedCells} 
          setSelectedCells={setSelectedCells} 
          targetNumber={targetNumber} 
          highestChain={highestChain} 
          setHighestChain={setHighestChain} 
          attemptNumber={attemptNumber} 
          setAttemptNumber={setAttemptNumber} 
          resetGame={resetGame} 
          updateScoreboard={updateScoreboard}
          gameCompleted={gameCompleted}
          setStepCounter={setStepCounter}
        />
        <div id="button-container">
          <div className="game-buttons">
            <button className="back" onClick={() => undoSelection()}>
              <span className="label">Back</span>
            </button>
            <div className="steps">
              <span className="counter">{stepCounter}</span>
              <span className="label">Length</span>
            </div>
            <button className="restart" onClick={() => resetGame()}>
              <span className="label">Restart</span>
            </button>
          </div>
          <a className="help" href="#how-to-play">
            Help
          </a>
        </div>
        <div className="attempts">
        {attemptIcons.map((data, index) => (
          <div key={index}>
            <img src={data} alt={`Attempt ${index+1}`} height="30" />
          </div>
        ))}
        </div>
        <table id="scoreboard">
          <thead>
            <tr>
              <th>Attempt</th>
              <th>Length</th>
            </tr>
          </thead>
          <tbody id="scoreboard-body">
            {scoreboardData.map((data, index) => (
              <tr key={index}>
                <td>{data.attemptNumber}</td>
                <td>{data.chainLength}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="statistics">
          <p>Your longest chain ever: <strong>{stats && stats.highestChain}</strong><br/>
          Total days played: <strong>{stats && stats.totalGamesPlayed}</strong></p>
        </div>

        <div id="how-to-play" className="howto">
          <h2>How to play</h2>
          <p><strong>Finding 20</strong> is a daily mindful activity, designed to focus the mind and give you a short break from the chaos of the day.</p>
          <p>You're with friends and we're all searching for the same thing - to find the longest combination of numbers to equal 20.</p>
          <p>You can start anywhere on the grid. To create your chain, tap numbers that are vertically or horizontally adjacent, but not diagonal.</p>
          <p>And remember, negative numbers will make for longer chains.</p>
          <p>Keep on going until your chain adds up to 20.</p>
          <p>Create five chains, compare your best with the rest of the world, then get back to your day having enjoyed a quiet moment.</p>
          <p className="last-para">A new puzzle is released daily at midnight (UTC).</p>
        </div>

        <div className="spacer">
          <img src="/images/heart-full.png" alt="" height="30"/>
        </div>

        <div className="about">
          <h2>About</h2>
          <p>We make Town Squared, a beautiful multiplayer world for kids aged 7 to 11, that weaves maths concepts into exciting gameplay to build confidence and enjoyment.</p>
          <p>But why should kids have all the fun?</p>
          <p>We believe everyone can enjoy maths, so we made Finding 20 for parents and carers to enjoy.</p>
          <p>Find out more about Town Squared at</p>
          <p className="last-para"><a className="web" href="https://www.townsquaredgame.com" target="_blank" rel="noreferrer">www.townsquaredgame.com</a></p>
        </div>
      </div>
      <div className="footer">
        <p>Made with 💛 in Melbourne, Australia<br />
        &copy; 2020 - 2024 Playlunch Games Pty Ltd<br />
        All rights reserved</p>
      </div>
    </div>  
  )
};
