import React, { useEffect } from 'react';

export default function GameBoard({
  cells, 
  setCells, 
  selectedCells, 
  setSelectedCells, 
  targetNumber, 
  highestChain, 
  setHighestChain, 
  attemptNumber, 
  setAttemptNumber, 
  resetGame, 
  updateScoreboard, 
  gameCompleted,
  setStepCounter
}) {
  useEffect(() => {
    const today = new Date();
    const seed = today.getUTCFullYear() + ' ' + today.getUTCMonth() + ' ' + today.getUTCDate();
    const rand = require('random-seed').create(seed);

    const generateRandomNumber = () => Math.floor(rand.random() * Math.floor(targetNumber / 5)) + 1;
    const generateRandomNegativeNumber = () => Math.floor(rand.random() * 2) - 2;

    // Create an array containing numbers from 1 to length
    const originalArray = Array.from({length: 80}, (_, index) => index + 1);

    // Fisher-Yates shuffle algorithm (thanks ChatGPT!)
    for (let i = originalArray.length - 1; i > 0; i--) {
      const j = Math.floor(rand.random() * (i + 1));
      [originalArray[i], originalArray[j]] = [originalArray[j], originalArray[i]];
    }

    const newCells = [];
    const maxNegs = Math.floor(80 * 0.1)
    let randomNumber = 0;
    let index = -1;

    for (let i = 0; i < 10; i++) {
      for (let j = 0; j < 8; j++) {
        if(originalArray[index] >= maxNegs){
          randomNumber = generateRandomNumber();
        } else {
          randomNumber = generateRandomNegativeNumber();
        }
        newCells.push({ number: randomNumber, row: i, col: j });
        index +=1;
      }
    }

    setCells(newCells);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to ensure the effect runs only once

  const handleCellClick = (clickedCell) => {
    // Handle cell click logic
    if(attemptNumber < 6) {
      // Prevent clicking on already selected cells
      if (isSelected(clickedCell.row, clickedCell.col)) {
        return;
      }

      if (selectedCells.length === 0 || isValidSelection(clickedCell.row, clickedCell.col)) {
        const updateSelectedCells = selectedCells;
        updateSelectedCells.push(clickedCell);
        setSelectedCells([...updateSelectedCells]);
        // console.log(selectedCells);

        setCells(prevCells =>
          prevCells.map(cell =>
            (cell.row === clickedCell.row && cell.col === clickedCell.col)
              ? { ...cell, isSelected: true } // never unselect on click
              : cell
          )
        );

        const sum = calculateSelectedCellsSum();
        setStepCounter(selectedCells.length);

        if (sum === targetNumber) {   
          updateScoreboard(selectedCells.length);

          // Update the highest chain if needed
          const dayHighestChain = Math.max(highestChain, selectedCells.length);
          console.log("dayHighestChain", dayHighestChain);
          setHighestChain(dayHighestChain);
          alert(`Brilliant You reached 20 in ${selectedCells.length} links.`);
          
          if (attemptNumber === 5) {
            gameCompleted(dayHighestChain);
            alert(`Nice! Your best chain today was ${dayHighestChain}. Embrace the day and we'll see you tomorrow!`);
          }

          // Reset the game only after the player dismisses the alert
          setTimeout(resetGame, 500);
        } else if (sum > targetNumber) {
          alert("Whoopsie! The sum exceeded 20. Have another try!");
          // Reset the game only after the player dismisses the alert
          setTimeout(resetGame, 0);
        }
      } else {
          // Shade the invalid cell in pink
          // console.log("invalid")
          setCells(prevCells =>
            prevCells.map(cell =>
              (cell.row === clickedCell.row && cell.col === clickedCell.col)
                ? { ...cell, isSelected: false, isInvalid: true } // never unselect on click
                : cell
            )
          );
          setTimeout(() => {
            setCells(prevCells =>
              prevCells.map(cell =>
                (cell.row === clickedCell.row && cell.col === clickedCell.col)
                  ? { ...cell, isSelected: false, isInvalid: false } // never unselect on click
                  : cell
              )
            );
          }, 500);
      }
    }
    else {
      alert("It's great to see you again, but you've already played today. Come back tomorrow!");
    }
  };


  function isValidSelection(row, col) {
    // Check if the selected cell is adjacent to the last selected cell
    const lastCell = selectedCells[selectedCells.length - 1];

    if (!lastCell) {
        // If selectedCells is empty, any cell is a valid selection
        return true;
    }

    return (
        !isSelected(row, col) &&
        ((Math.abs(row - lastCell.row) === 1 && col === lastCell.col) ||
            (row === lastCell.row && Math.abs(col - lastCell.col) === 1))
    );
  }

  function isSelected(row, col) {
    return selectedCells.some(cell => cell.row === row && cell.col === col);
  }

  function calculateSelectedCellsSum() {
    return selectedCells.reduce((sum, cell) => {
        const number = cell.number;
        return sum + number;
    }, 0);
  }

  return (
    <div id="game-board">
      {cells.map((cell, index) => (
        <Cell key={index} number={cell.number} row={cell.row} col={cell.col} isSelected={cell.isSelected} isInvalid={cell.isInvalid}
        onClick={() => handleCellClick(cell)} />
      ))}
    </div>
  );
}

function Cell({ number, row, col, isSelected, isInvalid, onClick }) {
  const cellStyle = {
    backgroundColor: isSelected ? "#584E3B" : isInvalid ? "#FFC0CB": "",
    color: isSelected ? "#FFFAE5" : "",
  };

  return (
    <div className="cell" data-row={row} data-col={col} style={cellStyle} onClick={onClick}>
      {number}
    </div>
  );
}

// Usage example:
// <GameBoard />
